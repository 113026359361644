<template>
	
</template>

<style lang="css">

</style>

<script>
export default {
	data: () => ({
		
	}),
	methods: {
		
	},
	components: {
		
	},
}
</script>