import { createStore } from 'vuex';
import auth from './auth.js';
//import notice from 'io7/store/notice.js';

const store = createStore({
	modules: {
		auth,
		//notice
	},
});


export default store;